.mp-table {
  background-color: #fff;
  color: #5b6271;
  cursor: default;
  display: flex;
  flex-direction: column;
}
.mp-table-header {
  align-items: center;
  border-bottom: 1px solid #cfd6e4;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  height: 59px;
}
.mp-table-header .mp-table-header-title {
  color: #5b6271;
  font-size: 16px;
  font-weight: 500;
}
.mp-table-titles {
  background-color: #f3f6fb;
  border-bottom: 1px solid #cfd6e4;
  display: flex;
  height: 39px;
}
.mp-table-title-cell {
  align-items: center;
  display: flex;
  flex: 1 0;
  font-size: 14px;
  font-weight: 500;
  padding: 0 22px;
}
.mp-table-title-cell .mp-table-sort-controls {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 2px;
}
.mp-table-title-cell .mp-table-sort-controls svg-icon {
  display: inline-block;
  height: 14px;
  min-height: 14px;
  min-width: 14px;
  position: relative;
  width: 14px;
}
.mp-table-title-cell .mp-table-sort-controls svg-icon svg {
  display: block;
  height: 14px;
  left: 0;
  min-height: 14px;
  min-width: 14px;
  position: absolute;
  top: 0;
  width: 14px;
}
.mp-table-title-cell .mp-table-sort-controls svg-icon svg {
  color: #858d9f;
}
.mp-table-title-cell .mp-table-sort-controls.mp-table-sort-asc .mp-table-sort-caret-down svg-icon {
  display: inline-block;
  height: 14px;
  min-height: 14px;
  min-width: 14px;
  position: relative;
  width: 14px;
}
.mp-table-title-cell .mp-table-sort-controls.mp-table-sort-asc .mp-table-sort-caret-down svg-icon svg {
  display: block;
  height: 14px;
  left: 0;
  min-height: 14px;
  min-width: 14px;
  position: absolute;
  top: 0;
  width: 14px;
}
.mp-table-title-cell .mp-table-sort-controls.mp-table-sort-asc .mp-table-sort-caret-down svg-icon svg {
  color: #37383c;
}
.mp-table-title-cell .mp-table-sort-controls.mp-table-sort-desc .mp-table-sort-caret-up svg-icon {
  display: inline-block;
  height: 14px;
  min-height: 14px;
  min-width: 14px;
  position: relative;
  width: 14px;
}
.mp-table-title-cell .mp-table-sort-controls.mp-table-sort-desc .mp-table-sort-caret-up svg-icon svg {
  display: block;
  height: 14px;
  left: 0;
  min-height: 14px;
  min-width: 14px;
  position: absolute;
  top: 0;
  width: 14px;
}
.mp-table-title-cell .mp-table-sort-controls.mp-table-sort-desc .mp-table-sort-caret-up svg-icon svg {
  color: #37383c;
}
.mp-table-title-cell .mp-table-sort-controls .mp-table-sort-caret-up {
  height: 6px;
}
.mp-table-title-cell .mp-table-sort-controls .mp-table-sort-caret-down {
  height: 14px;
}
.mp-table-title-cell:not(.mp-table-sort-column) .mp-table-sort-controls {
  visibility: hidden;
}
.mp-table-title-cell.mp-table-can-sort:hover {
  cursor: pointer;
}
.mp-table-title-cell.mp-table-can-sort:hover .mp-table-sort-controls {
  visibility: visible;
}
.mp-table-body.mp-table-body-clickable {
  cursor: pointer;
}
.mp-table-body.mp-table-body-clickable .mp-table-row:not(.mp-table-row-empty):hover {
  background-color: #f3f6fb;
}
.mp-table-row {
  align-items: center;
  border-bottom: 1px solid #cfd6e4;
  display: flex;
  flex-direction: row;
  min-height: 60px;
  position: relative;
}
.mp-table-row:last-child {
  border-bottom: none;
}
.mp-table-cell {
  align-items: center;
  display: flex;
  flex: 1 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  min-height: 20px;
  padding: 0 22px;
}
mp-gdpr-settings-privacy {
  background: #fff;
}
mp-gdpr-settings-privacy .mp-table-header,
mp-gdpr-settings-privacy .mp-table-row {
  display: block;
  height: auto;
  padding: 24px;
}
mp-gdpr-settings-privacy .mp-table-row {
  display: flex;
}
mp-gdpr-settings-privacy .gdpr-settings-privacy-header {
  color: #37383c;
  font-size: 14px;
  font-weight: 500;
}
mp-gdpr-settings-privacy mp-switch {
  align-self: self-start;
  margin-left: 24px;
}